import { type App, computed, type DeepReadonly, inject, type InjectionKey, readonly, type Ref, ref, watch } from 'vue';
import { getNowISO } from '@/utils/dateUtils';

export interface LogEntry {
  date: string;
  level: 'info' | 'warn' | 'error';
  message: string[];
}

type _LogEntriesRef = DeepReadonly<Ref<LogEntry[]>>;
export const logsKey: InjectionKey<_LogEntriesRef> = Symbol('logs');
export const clearLogsKey: InjectionKey<() => void> = Symbol('clearLogs');

const mapToStrings = (args: any[]) => {
  return args.map((arg) => {
    if (typeof arg === 'string') {
      return arg;
    } else {
      return '' + arg;
    }
  });
};

export const LogsPlugin = {
  install(app: App) {
    const logs = ref<LogEntry[]>([]);
    const clearLogs = () => {
      logs.value = [];
    };
    app.provide(logsKey, readonly(logs));
    app.provide(clearLogsKey, clearLogs);

    const originalConsole = {
      log: console.log,
      warn: console.warn,
      error: console.error,
    };

    const _logsLength = computed(() => logs.value.length);

    watch(_logsLength, (newLength) => {
      if (newLength >= 36_000) {
        // remove oldest  1_000 logs
        logs.value = logs.value.slice(1_000);
      }
    });

    // Function to add log entries
    function addLogEntry(date: string, method: 'log' | 'warn' | 'error', args: any[]) {
      const level = method === 'log' ? 'info' : method;

      logs.value.push({
        date,
        level,
        message: mapToStrings(args),
      });

      originalConsole[method].apply(console, args);
    }

    console.log = (...args) => addLogEntry(getNowISO(), 'log', args);
    console.warn = (...args) => addLogEntry(getNowISO(), 'warn', args);
    console.error = (...args) => addLogEntry(getNowISO(), 'error', args);
  },
};

export default LogsPlugin;

export const useLogs = () => {
  const $logs = inject(logsKey) as Ref<LogEntry[]>;
  const clearLogs = inject(clearLogsKey) as () => void;

  return { $logs, clearLogs };
};
