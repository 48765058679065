export enum SessionLength {
  LONG = 'long',
  SHORT = 'short',
}

export enum BrowserStorageKey {
  LANGUAGE = 'locale',
  LOGIN_TOKEN = 'token',
  LOGGED_IN_USER_UUID = 'user_uuid',
  LOGIN_TOKEN_EXPIRY = 'expiry_timestamp',
  DOMAIN = 'loginDomain',
  SESSION_LENGTH = 'sessionLength', //data about the storage option
  SETTINGS_DATA = 'SettingsData',
  OUTLOOK_CONTACTS = 'OutlookContacts',
  EXTERNAL_RINGTONE = 'ExternalRingtone',
  INTERNAL_RINGTONE = 'InternalRingtone',
}

export enum ElecrtonStorageKey {
  SHOWN_ACCESSABILITY_PERMISSIONS = 'SHOWN_ACCESSABILITY_PERMISSIONS',
}
