import { defineStore } from 'pinia';
import { computed, readonly, ref } from 'vue';
import type { SessionDTO } from '@/data/Session';
import { getSessionDataFromStorage } from '@/api/authentication';
import { useQueryClient } from '@tanstack/vue-query';
import { useUserStore } from '@/stores/useUserStore';
import { SessionLength } from '@/constants/browserStorage';
import { isElectron } from '@/util';
import { startNewSession } from '@/utils/sessionMetricsUtils';
import { useSessionMetricsStore } from '@/stores/useSessionMetricsStore';

interface Session {
  token?: string;
  expiryDate?: string;
  domain?: string;
  userUuid?: string;
  isAuthenticated: boolean;
  sessionLength?: SessionLength;
}

const initSessionData = (): Session => {
  const { token, user_uuid, domain, expiry_timestamp, sessionLength } = getSessionDataFromStorage();
  return {
    isAuthenticated: false,
    token,
    userUuid: user_uuid,
    domain,
    expiryDate: expiry_timestamp,
    sessionLength: sessionLength,
  };
};

export const useSessionStore = defineStore('authenticatedStore', () => {
  const __session = ref<Session>(initSessionData());
  const queryClient = useQueryClient();
  const $metricsStore = useSessionMetricsStore();

  const createSession = (data: SessionDTO & { sessionLength?: SessionLength; domain: string }) => {
    __session.value = {
      token: data.token,
      expiryDate: data.expiry_timestamp,
      domain: data.domain,
      userUuid: data.user_uuid,
      sessionLength: data.sessionLength ?? SessionLength.SHORT,
      isAuthenticated: true,
    };
    startNewSession(data.user_uuid);
  };

  const logoutSession = async () => {
    try {
      await queryClient.cancelQueries();
      $metricsStore.endSession();
      __session.value = {
        isAuthenticated: false,
        sessionLength: __session.value.sessionLength,
      };
      // todo probably not need to clear it
      await useUserStore().clearState();
      // clearStorageData()
    } catch (e) {
      console.error(e, 'Error occurred during closing session.');
    }
  };

  const _session = readonly(__session);
  const _hasLoginData = computed(() => {
    const hasCredentials = !!_session.value.token && _session.value.expiryDate && !!_session.value.domain && !!_session.value.userUuid;
    if (isElectron()) return hasCredentials && _session.value.sessionLength === SessionLength.LONG;
    return hasCredentials;
  });

  return { _session, _hasLoginData, createSession, logoutSession };
});
