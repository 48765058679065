import { parseDataFromBrowserStorage, saveToBrowserStorage } from '@/utils/storage';
import { getNowInSeconds } from '@/utils/dateUtils';
import { isToday } from 'date-fns';

export interface UserSessionMetrics {
  lastHomepageVisitDate?: number; //epoch to seconds
  totalSessionTime: number; // in seconds
  sessionStartTime: number; // ISO string format
  sessionEndTime: number; // ISO string format
}

const initEmptyMetricsSession = () => ({
  sessionStartTime: getNowInSeconds(),
  totalSessionTime: 0,
  sessionEndTime: 0,
});

export const saveUserSessionMetrics = (userUuid: string, data: Partial<UserSessionMetrics>) => {
  const metrics = getGlobalSessionMetrics(userUuid);
  saveToBrowserStorage(userUuid, { ...metrics, ...data });
};

const saveUserMetrics = (userUuid: string, data: Partial<UserSessionMetrics> | undefined) => {
  saveToBrowserStorage(userUuid, { ...data });
};

export const getGlobalSessionMetrics = (userUuid: string): UserSessionMetrics => {
  const data = parseDataFromBrowserStorage<UserSessionMetrics>(userUuid);
  if (!data || data.sessionStartTime == null || data.sessionEndTime == null || data.totalSessionTime == null) {
    return { ...initEmptyMetricsSession(), ...data };
  }
  return data;
};

export const startNewSession = (userUuid: string) => {
  let globalSessionMetrics = getGlobalSessionMetrics(userUuid);

  // reset if after midnight
  if (!isToday(globalSessionMetrics.sessionStartTime * 1_000)) {
    globalSessionMetrics = { ...globalSessionMetrics, ...initEmptyMetricsSession() };
  } else {
    globalSessionMetrics.sessionStartTime = getNowInSeconds();
  }
  saveUserMetrics(userUuid, globalSessionMetrics);
};

export const updateMetrics = (userUuid: string) => {
  let globalSessionMetrics = getGlobalSessionMetrics(userUuid);
  if (!isToday(globalSessionMetrics.sessionStartTime * 1_000)) {
    globalSessionMetrics = { ...globalSessionMetrics, ...initEmptyMetricsSession() };
  } else {
    const now = getNowInSeconds();
    const tabSessionTime = now - globalSessionMetrics.sessionStartTime;
    globalSessionMetrics.totalSessionTime += tabSessionTime;
    globalSessionMetrics.sessionStartTime = now;
  }
  saveUserMetrics(userUuid, globalSessionMetrics);
  return globalSessionMetrics;
};

export const onEndSession = (userUuid: string) => {
  updateMetrics(userUuid);
};

export const getLastHomepageVisit = (userUuid: string) => {
  const sessionMetrics = getGlobalSessionMetrics(userUuid);
  return sessionMetrics?.lastHomepageVisitDate || 0;
};
