import { defineStore } from 'pinia';
import type { User } from '@/data/User';
import { buildFormData, httpPost } from '@/api/http';
import { USERS } from '@/constants/endpoint';
import { computed, readonly, ref, watch } from 'vue';
import type { StatusType } from '@/data/UserStatus';
import { useQueryClient } from '@tanstack/vue-query';
import { useContacts } from '@/api/useContacts';
import { LOGGED_IN_USER_QUERY_KEY, useLoggedInUser } from '@/api/useLoggedInUser';
import type { ContactExtended } from '@/data/contacts/Contact';
import { USERS_STATUS_QUERY_KEY } from '@/api/useUsersStatus';
import type { Agent } from '@/data/Agent';

export interface LoggedUser extends User {
  domain: string;
  contact?: ContactExtended;
  agent?: Agent;
}

export const useUserStore = defineStore('user', () => {
  const __stayLoggedIn = ref(false);
  const queryClient = useQueryClient();
  const { data: loggedUser } = useLoggedInUser();
  const { data: contacts } = useContacts();

  const _contact = computed(() => {
    return contacts.value?.find((contact) => contact.uuid === loggedUser.value?.contactUuid);
  });

  const _loggedInUser = computed(() => {
    return {
      ...loggedUser.value,
      contact: _contact.value,
      userStatus: _contact.value?.user?.userStatus,
    } as LoggedUser;
  });

  const setUserStatusType = async (updatedStatus: StatusType) => {
    httpPost(USERS, buildFormData({ user_status_type: updatedStatus }), {
      params: {
        user_uuid: _loggedInUser.value.uuid,
        action: 'set_status',
      },
    }).then((_) => {
      queryClient.invalidateQueries({ queryKey: [USERS_STATUS_QUERY_KEY] });
    });
  };

  const setStayLoggedIn = (val: boolean) => {
    __stayLoggedIn.value = val;
  };
  const _stayLoggedIn = readonly(__stayLoggedIn);

  const clearState = async () => {
    queryClient.removeQueries({ queryKey: [LOGGED_IN_USER_QUERY_KEY] });
  };

  // It's a rare corner case that may be resolved differently in the future - https://jira.wtg-innovation.com/browse/WCP-961
  watch(
    () => loggedUser.value?.extension?.extension,
    (newExtension, oldExtension) => {
      if (oldExtension && oldExtension !== newExtension) {
        location.reload();
      }
    }
  );

  return {
    _loggedInUser,
    _stayLoggedIn,
    setStayLoggedIn,
    setUserStatusType,
    clearState,
  };
});
