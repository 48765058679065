import type { UseQueryReturnType } from '@tanstack/vue-query';
import { httpGet } from '@/api/http';
import { USERS } from '@/constants/endpoint';
import type { AxiosError } from 'axios';
import type { UserStatusDTO } from '@/data/UserStatus';
import { useAuthenticatedQuery } from '@/utils/query';

export const USERS_STATUS_QUERY_KEY = 'users_status';

type UsersStatusApiData = Record<string, UserStatusDTO>;

// need to explicitly type due to issue
// https://github.com/TanStack/query/issues/6318
export const useUsersStatusQuery = {
  queryKey: [USERS_STATUS_QUERY_KEY],
  queryFn: () => httpGet<{ statuses: UsersStatusApiData }>(`${USERS}?get=status`).then((r) => r.statuses),
  refetchInterval: 10_000,
  refetchOnMount: false,
};

export const useUsersStatus = (): UseQueryReturnType<UsersStatusApiData, AxiosError<unknown, any>> => {
  return useAuthenticatedQuery(useUsersStatusQuery);
};
