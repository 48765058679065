import { httpGet } from '@/api/http';
import type { UseQueryReturnType } from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import { useAuthenticatedQuery } from '@/utils/query';
import { normalizeArrayToMapByKey } from '@/utils/dataTransformationUtils';

export interface PresenceDTO {
  extension_uuid: string;
  extension: number;
  present: boolean;
  in_call: boolean;
}

export const PRESENCE_QUERY_KEY = 'presence';

export const presenceQuery = {
  queryKey: [PRESENCE_QUERY_KEY],
  queryFn: () =>
    httpGet<{ extensions: PresenceDTO[] }>('/presence.php')
      .then((r) => r.extensions)
      .then(normalizePresence),
  refetchInterval: 10_000,
  refetchOnMount: false,
};

export const usePresence = (): UseQueryReturnType<Map<string, PresenceDTO>, AxiosError<unknown, any>> => {
  return useAuthenticatedQuery(presenceQuery);
};

const normalizePresence = (presenceExtensions: PresenceDTO[]) => {
  return normalizeArrayToMapByKey(presenceExtensions, 'extension_uuid');
};
