import type { Extension, ExtensionDTO } from './Extension';
import { mapExtensionDTOtoExtension } from './Extension';
import type { Presence, Uuid } from './Types';
import type { Identifiable } from './Identifiable';
import type { StatusType } from './UserStatus';
import type { UserPermission } from '@/stores/usePermissionsStore';

export interface Group {
  uuid: string;
  name: UserPermission;
}

export interface GroupDTO {
  user_uuid: string;
  group_name: UserPermission;
  group_uuid: string;
}

export interface UserData extends Identifiable {
  uuid: Uuid;
  contactUuid: Uuid | null;
  username: string;
  extensions: Extension[];
  groups: Group[];
  agentUuids: string[];
}

export interface UserDTO {
  user_uuid: string;
  username: string;
  contact_uuid: string;
  user_status_type: StatusType;
  user_status_last_update_epoch: number;
  extensions: ExtensionDTO[];
  groups: GroupDTO[];
  agents: string[];
}

export const mapGroupDTOtoGroup = (dto: GroupDTO): Group => {
  return {
    uuid: dto.group_uuid,
    name: dto.group_name,
  };
};

export const mapUserDTOtoUser = (dto: UserDTO): UserData => {
  return {
    uuid: dto.user_uuid,
    contactUuid: dto.contact_uuid || null,
    username: dto.username,
    extensions: dto.extensions?.map(mapExtensionDTOtoExtension), // Assuming mapExtensionDTOtoExtension is defined elsewhere
    groups: dto.groups.map(mapGroupDTOtoGroup),
    agentUuids: dto.agents,
  };
};

export interface User {
  uuid: Uuid;
  contactUuid: Uuid | null;
  username: string;
  extensions: Extension[];
  groups: Group[];
  agentUuids: string[];
  extension: Extension | null;
  presence?: Presence | null;
  name: string;
  userStatus?: StatusType | null;
}
