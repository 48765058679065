import { BrowserStorageKey, SessionLength } from '@/constants/browserStorage';
import { isElectron } from '@/util';

export const saveToBrowserStorage = (key: string, value: any, storage = localStorage) => {
  if (!key) return;
  if (!value) storage.removeItem(key);
  else {
    const _value = typeof value !== 'string' ? JSON.stringify(value) : value;
    storage.setItem(key, _value);
  }
};
export const parseDataFromBrowserStorage = <T>(key: string, storage = localStorage): T | null => {
  const data = storage.getItem(key);
  if (!data) return null;
  try {
    return JSON.parse(data);
  } catch (e) {
    console.error(`Parsing ${key} caused an error`, e);
    return null;
  }
};

export const getDataFromBrowserStorage = (key: string, storage?: Storage) => {
  const _storage = storage ?? getStorage();
  return _storage.getItem(key);
};

export const getStorage = () => {
  if (isElectron()) return localStorage;
  const storage = sessionStorage.getItem(BrowserStorageKey.SESSION_LENGTH) || localStorage.getItem(BrowserStorageKey.SESSION_LENGTH);
  return storage === SessionLength.LONG ? localStorage : sessionStorage;
};
