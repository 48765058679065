import { reactive } from 'vue';

export function isElectron() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(' electron/') > -1;
}

export function isWindows() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('(windows') > -1;
}

export function isMac() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('mac os x') != -1;
}

const tickTock: Record<number, boolean> = reactive({});

export function getCurrentTime(frequency: number): number {
  if (frequency in tickTock) {
    return Date.now();
  } else {
    tickTock[frequency] = false;
    setInterval(() => {
      tickTock[frequency] = !tickTock[frequency];
    }, frequency);
    return Date.now();
  }
}

export function getPlatformString() {
  return `${isElectron() ? 'electron' : 'web'}, ${isWindows() ? 'windows' : 'unix'}`;
}

export function getRevision(): string {
  return `v${APP_REVISION}-${GIT_HASH}`;
}
