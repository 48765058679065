<template>
  <router-view />
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router';
import { watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSessionStore } from '@/stores/useSessionStore';
import { saveToBrowserStorage } from '@/utils/storage';
import { BrowserStorageKey, SessionLength } from '@/constants/browserStorage';
import { isElectron } from '@/util';

const $session = useSessionStore();
const { locale } = useI18n();

watchEffect(() => {
  // for electron credentials need to be stored in localstorage to be able to download toast avatar that is opened in new window
  const browserStorage = $session._session.sessionLength === SessionLength.LONG || isElectron() ? localStorage : sessionStorage;
  saveToBrowserStorage(BrowserStorageKey.LANGUAGE, locale.value);
  saveToBrowserStorage(BrowserStorageKey.LOGIN_TOKEN, $session._session.token, isElectron() ? localStorage : browserStorage);
  saveToBrowserStorage(BrowserStorageKey.LOGIN_TOKEN_EXPIRY, $session._session.expiryDate, isElectron() ? localStorage : browserStorage);
  saveToBrowserStorage(BrowserStorageKey.LOGGED_IN_USER_UUID, $session._session.userUuid, isElectron() ? localStorage : browserStorage);
  saveToBrowserStorage(BrowserStorageKey.DOMAIN, $session._session.domain, isElectron() ? localStorage : browserStorage);
  saveToBrowserStorage(BrowserStorageKey.SESSION_LENGTH, $session._session.token ? $session._session.sessionLength : null, browserStorage);
});
</script>
