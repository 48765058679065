import type { QueryObserverResult, RefetchOptions } from '@tanstack/vue-query';
import type { User, UserDTO } from '@/data/User';
import { mapGroupDTOtoGroup } from '@/data/User';
import { httpGet } from '@/api/http';
import { USERS } from '@/constants/endpoint';
import { mapExtensionDTOtoExtension } from '@/data/Extension';
import { computed, type Ref } from 'vue';
import { useUsersStatus } from '@/api/useUsersStatus';
import { usePresence } from '@/api/usePresence';
import { useAuthenticatedQuery } from '@/utils/query';

export const USERS_QUERY_KEY = 'users';

// need to explicitly type due to issue
// https://github.com/TanStack/query/issues/6318
export const usersQuery = {
  queryKey: [USERS_QUERY_KEY],
  queryFn: () =>
    httpGet<{ users: UserDTO[] }>(USERS)
      .then((r) => r.users)
      .then((usersDto) => usersDto.map(mapToUser)),
  refetchInterval: 600_000,
  refetchOnMount: false,
  gcTime: Infinity,
};

export const useUsers = (): {
  data: Ref<Map<string, User> | undefined>;
  isLoading: Ref<boolean>;
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<User[], unknown>>;
} => {
  const { data: _users, isLoading, refetch } = useAuthenticatedQuery(usersQuery);
  const $statuses = useUsersStatus();
  const $presences = usePresence();

  // todo verify it runs every 10 sek
  const data = computed(() => {
    const map = new Map();
    const users = _users.value ?? ([] as User[]);
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      const extendedUser = {
        ...user,
        userStatus: $statuses.data.value?.[user.uuid]?.user_status_type,
        presence: user.extension?.uuid ? $presences.data.value?.get(user.extension.uuid) : null,
      };
      if (user.uuid) {
        map.set(user.uuid, extendedUser);
      }
    }
    return map;
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export const mapToUser = (user: UserDTO): User => {
  const extensions = user.extensions?.map(mapExtensionDTOtoExtension) || [];
  return {
    uuid: user.user_uuid,
    contactUuid: user.contact_uuid || null,
    username: user.username,
    extensions,
    groups: user.groups.map(mapGroupDTOtoGroup),
    agentUuids: user.agents,
    extension: extensions.find((extension) => extension.enabled) || null,
    name: user.username,
    userStatus: user.user_status_type,
  };
};
