import { createI18n } from 'vue-i18n';
import en from '../locales/en.json';
import de from '../locales/de.json';
import fr from '../locales/fr.json';
import cs from '../locales/cs.json';
import { BrowserStorageKey } from '@/constants/browserStorage';

export const i18n = createI18n({
  locale: localStorage.getItem(BrowserStorageKey.LANGUAGE) || 'de',
  fallbackLocale: 'en',
  legacy: false,
  messages: {
    en,
    de,
    cs,
    fr,
  },
});
