import { httpGet } from '@/api/http';
import type { UseQueryReturnType } from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import { FAVORITES } from '@/constants/endpoint';
import { useAuthenticatedQuery } from '@/utils/query';
import { normalizeArrayToMapByKey } from '@/utils/dataTransformationUtils';

export const FAVORITES_QUERY_KEY = 'favorites';

export const favoritesQuery = {
  queryKey: [FAVORITES_QUERY_KEY],
  queryFn: () =>
    httpGet<{ contacts: { contact_uuid: string }[] }>(FAVORITES)
      .then((response) => response.contacts)
      .then((contacts) => normalizeArrayToMapByKey(contacts, 'contact_uuid')),
  refetchInterval: 600_000,
  refetchOnMount: false,
};
export const useFavorites = (): UseQueryReturnType<Map<string, { contact_uuid: string }> | undefined, AxiosError<unknown, any>> => {
  return useAuthenticatedQuery(favoritesQuery);
};
