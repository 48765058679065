import { buildAuthorizationHeader, buildFormData, builtDomainUrl, createDomainAxiosInstance, httpPost } from '@/api/http';
import axios, { AxiosError } from 'axios';
import { getRevision, isElectron, isWindows } from '@/util';
import { BrowserStorageKey, SessionLength } from '@/constants/browserStorage';
import type { BrowserSession, SessionDTO } from '@/data/Session';
import { LOGIN } from '@/constants/endpoint';
import { useSessionStore } from '@/stores/useSessionStore';
import { getDataFromBrowserStorage } from '@/utils/storage';
import { getNowInSeconds } from '@/utils/dateUtils';
import { isErrorWithMessage } from '@/utils/errorUtils';

export interface LoginProps {
  email: string;
  password: string;
  sessionLength: SessionLength;
  locale: string;
}

export const login = async (
  props: LoginProps
): Promise<(SessionDTO & { domain: string } & { status: 'success' }) | { status: 'unauthorized' | 'connectionfailure' | 'inactiveUser' }> => {
  const { email, password, sessionLength } = props;
  const [username, domain] = email.split('@');
  const baseURL = builtDomainUrl(domain);
  const URL = `${baseURL}/login.php`;

  // todo make sure that the case with special user is not needed
  // if(username.startsWith('$')) {}
  const data = buildFormData({
    username,
    password,
    domain,
    client_info: getClientInfo(),
    duration: 'long',
  });
  try {
    const response = await axios.post<SessionDTO>(URL, data);
    const responseData = { ...response.data, domain };
    if (hasRequiredSessionDataValues(responseData)) {
      createDomainAxiosInstance(domain);
      return Promise.resolve({ ...responseData, status: 'success' });
    } else {
      //Can this ever happen and if it does what we should do?
      console.log('Not writing incomplete data to session storage');
      return Promise.resolve({ status: 'connectionfailure' });
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status == 401) {
        return Promise.resolve({ status: 'unauthorized' });
      }
      if (error.response?.status === 403) {
        return Promise.resolve({ status: 'inactiveUser' });
      }
    }
    if (isErrorWithMessage(error)) {
      console.log('Connection error:', error.message);
    }
    return Promise.resolve({ status: 'connectionfailure' });
  }

  // todo do we return unauthorized | connectionfailure as success? When it will be known this need to be refactored...
  // todo either to try/catch or then / catch / finally
};

export const logoutUser = async () => {
  const baseURL = builtDomainUrl(getDomain()!);
  const URL = `${baseURL}/login.php`;
  await axios.post<SessionDTO>(URL, null, { params: { action: 'logout' }, headers: buildAuthorizationHeader() });
};

const hasRequiredSessionDataValues = (sessionData: SessionDTO & { domain: string }) => {
  return !!sessionData.user_uuid && !!sessionData.token && !!sessionData.domain && !!sessionData.expiry_timestamp;
};

export const refreshAccessToken = async (): Promise<any> => {
  const $session = useSessionStore();
  const { domain, token, sessionLength } = getSessionDataFromStorage();
  if (!token || !domain) return Promise.reject('User has logged out.');
  const duration = sessionLength === SessionLength.LONG ? { duration: sessionLength } : null;
  const data: Record<string, string> = { client_info: getClientInfo(), ...duration };

  try {
    const response = await httpPost<
      Record<string, any>,
      {
        data: Omit<SessionDTO, 'domain'>;
      }
    >(LOGIN, buildFormData(data), { params: { action: 'renew' } });
    $session.createSession({ ...$session._session, domain: domain, ...response.data });
  } catch (error) {
    console.error('Error during token refresh.', error);
    throw new Error(`Error during token refresh + ${error}`);
    // todo think about retry mechanism
  }
};

export const isTokenNearlyExpired = () => {
  const now = Math.floor(Date.now() / 1000); // Current time in seconds
  const thresholdSeconds = 60; // 1 minute before expiration
  const expiryTimestamp = +(getExpirationToken() || 0);
  return expiryTimestamp - now < thresholdSeconds;
};

export const getClientInfo = (contactHeader?: string) => {
  return JSON.stringify({
    version: getRevision(),
    is_windows: isWindows(),
    is_electron: isElectron(),
    //todo probably can be removed. The value was returned from Capacitor
    platform: 'web',
    contact_header: contactHeader || 'none',
  });
};

const getLoggedInUserUuid = () => getDataFromBrowserStorage(BrowserStorageKey.LOGGED_IN_USER_UUID) ?? undefined;
export const getDomain = () => getDataFromBrowserStorage(BrowserStorageKey.DOMAIN) ?? undefined;
const getExpirationToken = () => getDataFromBrowserStorage(BrowserStorageKey.LOGIN_TOKEN_EXPIRY) ?? undefined;
const getLoginToken = () => getDataFromBrowserStorage(BrowserStorageKey.LOGIN_TOKEN) ?? undefined;
// if user has decided to stay logged in we make the session longer and will keep the data in local storage
const getSessionLength = () => (getDataFromBrowserStorage(BrowserStorageKey.SESSION_LENGTH) as SessionLength) ?? undefined;

export const getSessionDataFromStorage = (): BrowserSession => {
  return {
    sessionLength: getSessionLength(),
    domain: getDomain(),
    token: getLoginToken(),
    expiry_timestamp: getExpirationToken(),
    user_uuid: getLoggedInUserUuid(),
  };
};
const renewLogin = async (): Promise<SessionDTO & { domain: string }> => {
  const $session = useSessionStore();
  const domain = $session._session.domain;
  if (!domain) return Promise.reject(null);

  const baseURL = builtDomainUrl(domain);
  const URL = `${baseURL}/login.php`;

  const data: Record<string, string> = {
    client_info: getClientInfo(),
    duration: 'long',
  };

  const response = await axios.post<SessionDTO>(URL, buildFormData(data), { params: { action: 'renew' }, headers: buildAuthorizationHeader() });

  if (response.data?.token) {
    createDomainAxiosInstance(domain);
    const responseData = { ...response.data, domain };
    $session.createSession({ ...$session._session, domain, ...response.data });

    return responseData;
  } else {
    // todo error notification or error message
    console.error('Error during renewLogin, response.data = ', response.data);
    return Promise.reject(null);
  }
};

export const autoLogin = async () => {
  const { domain, token, expiry_timestamp, user_uuid } = getSessionDataFromStorage();
  if (expiry_timestamp != null && parseInt(expiry_timestamp) >= getNowInSeconds()) {
    // should have everything already checked before called
    if (!!token && !!expiry_timestamp && !!user_uuid && !!domain) return renewLogin();
    return Promise.reject(null);
  } else {
    return Promise.reject(null);
  }
};
