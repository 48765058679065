import { differenceInSeconds, formatISO } from 'date-fns';

export const getNowInSeconds = () => Math.floor(Date.now() / 1000);

export const getNowISO = () => formatISO(Date.now());

export const formatDuration = (startEpoch: number, endEpoch: number, inMinutes: boolean = false): string => {
  const totalSeconds = differenceInSeconds(new Date(endEpoch * 1000), new Date(startEpoch * 1000));

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (inMinutes) {
    return formatTimeDuration([hours * 60 + minutes, seconds]);
  }

  if (hours > 0 && !inMinutes) {
    return formatTimeDuration([hours, minutes, seconds]);
  }

  return formatTimeDuration([minutes, seconds]);
};

const formatTimeDuration = (arr: number[]) => {
  return arr.map((part) => String(part).padStart(2, '0')).join(':');
};
