import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useSessionStore } from '@/stores/useSessionStore';
import { getGlobalSessionMetrics, onEndSession, updateMetrics } from '@/utils/sessionMetricsUtils';

export interface LoginTime {
  loggedInMs: number;
  date: Date;
}

// todo move to composable not needed as store
export const useSessionMetricsStore = defineStore('useSessionMetricsStore', () => {
  const $session = useSessionStore();
  const isActive = ref(false);
  const timerInterval = ref<ReturnType<typeof setInterval> | null>(null);
  const timer = ref(0);

  const timeFromStorage = computed(() => {
    if (!$session._session.userUuid) return 0;
    const metrics = getGlobalSessionMetrics($session._session.userUuid);
    return metrics?.totalSessionTime ?? 0;
  });

  const startSessionTimer = () => {
    if (!isActive.value) {
      isActive.value = true;

      timerInterval.value = setInterval(() => {
        const updatedSessionMetrics = updateMetrics($session._session.userUuid!);
        timer.value = updatedSessionMetrics.totalSessionTime;
      }, 60_000); // Update every minute
    }
  };

  const clearSessionTimer = () => {
    if (timerInterval.value) {
      clearInterval(timerInterval.value);
      timerInterval.value = null;
      isActive.value = false;
    }
  };

  const endSession = () => {
    clearSessionTimer();
    onEndSession($session._session.userUuid!);
  };

  const totalTimeInSeconds = computed(() => {
    return timer.value || timeFromStorage.value;
  });

  return {
    startSession: startSessionTimer,
    endSession,
    minutes: computed(() => {
      return Math.floor(totalTimeInSeconds.value / 60) % 60;
    }),
    hours: computed(() => {
      return Math.floor(totalTimeInSeconds.value / 3600);
    }),
  };
});
