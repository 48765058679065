import type { StringKeyOf } from '@/utils/utilityTypes';

export function normalizeArrayToMapByKey<T, K extends StringKeyOf<T>>(arr: T[], key: K): Map<string, T> {
  const map = new Map<string, T>();
  for (let i = 0; i < arr.length; i++) {
    const currentItem = arr[i];
    if (currentItem[key]) {
      map.set(currentItem[key] as unknown as string, currentItem);
    }
  }
  return map;
}

export function normalizeArrayToMapByFunction<T, K extends keyof any>(arr: T[], keyFn: (item: T) => K): Map<string, T> {
  const map = new Map<string, T>();
  for (let i = 0; i < arr.length; i++) {
    const currentItem = arr[i];
    const key = keyFn(currentItem);
    if (key != null) {
      map.set(key as unknown as string, currentItem);
    }
  }
  return map;
}

export function groupArrayToMapByFunctionKey<T, K extends keyof any>(arr: T[], keyFn: (item: T) => K): Record<string, T[]> {
  const map = {} as Record<string, T[]>;
  for (let i = 0; i < arr.length; i++) {
    const currentItem = arr[i];
    const key = keyFn(currentItem);
    if (key != null) {
      if (!map[key as unknown as string]) {
        map[key as unknown as string] = [];
      }
      map[key as unknown as string].push(currentItem);
    }
  }
  return map;
}

export function normalizeArrayToMapWithValues<T, K extends keyof any, V>(
  arr: T[],
  keyFn: (item: T) => K,
  valueExtractor: (item: T) => V = () => true as unknown as V
): Map<K, V> {
  const map = new Map<K, V>();
  for (let i = 0; i < arr.length; i++) {
    const currentItem = arr[i];
    const key = keyFn(currentItem);
    const value = valueExtractor(currentItem);
    if (key) {
      map.set(key, value);
    }
  }
  return map;
}
