import type { Uuid } from '@/data/Types';

export interface Extension {
  uuid: Uuid;
  enabled?: boolean;
  extension: string;
  effectiveCallerIdNumber?: string;
  outboundCallerIdNumber?: string;
  password?: string;
  pickupGroupsNames: string[];
}

export interface ExtensionDTO {
  extension_uuid: string;
  enabled?: boolean;
  extension: string;
  effective_caller_id_number?: string;
  outbound_caller_id_number?: string;
  user_uuid: string;
  call_group: string[];
  password: string;
}

export const mapExtensionDTOtoExtension = (dto: ExtensionDTO): Extension => {
  return {
    uuid: dto.extension_uuid,
    enabled: dto.enabled,
    extension: dto.extension,
    effectiveCallerIdNumber: dto.effective_caller_id_number,
    outboundCallerIdNumber: dto.outbound_caller_id_number,
    pickupGroupsNames: dto.call_group,
    password: dto.password,
  };
};
